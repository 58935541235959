<template>
  <div class="page-container page--ticket">
    <div
      ref="content"
      class="ticket-info-container"
      @mouseenter="contentOver"
      @mouseleave="contentOut"
    >
      <div class="ticket-menu-container">
        <div
          v-for="(item, i) in tickets"
          :key="`menu-${i}`"
          @click="selectTicket(i)"
          :class="{
            'ticket-menu-item': true,
            'ticket-menu-item--active': currentTicket == i,
          }"
          v-html="item.name"
        ></div>
      </div>
      <div class="ticket-body-container">
        <transition-group name="fade" mode="out-in">
          <div
            class="ticket-body"
            v-for="(item, i) in tickets"
            :key="`body-${i}`"
            v-html="item.body"
            v-show="i == currentTicket"
          ></div>
        </transition-group>
      </div>
      <v-btn
        @click="getTicket"
        class="mt-5 btn-ticket black--text"
        elevation="0"
        x-large
        plain
      >
        博物館門票
      </v-btn>
    </div>
    <div ref="ticket" class="ticket-container">
      <div
        class="img-ticket-container"
        ref="card"
        :style="cardStyle"
        @mousemove="handleMouseMove"
        @mouseenter="handleMouseEnter"
        @mouseleave="handleMouseLeave"
      >
        <img
          src="@/assets/images/ticket.png"
          class="img-responsive img-ticket"
          alt=""
        />
      </div>
    </div>
    <div
      v-for="(item, i) in 3"
      :key="`ticket-svg-${i}`"
      v-show="currentTicket == i"
      :id="`ticket-${i}`"
      class="ticket-pattern-container"
    >
      <inline-svg
        v-if="i == 2"
        :src="require(`../assets/images/ticket-pattern-${i}-full.svg`)"
        @loaded="svgLoaded($event)"
      />
      <inline-svg
        v-else
        :src="require(`../assets/images/ticket-pattern-${i}.svg`)"
        @loaded="svgLoaded($event)"
      />
    </div>
  </div>
</template>

<script>
import { Howl, Howler } from "howler";
import { gsap, Power2, Power4 } from "gsap";
export default {
  data: () => ({
    localTracks: [null, null, null],
    mainTrack: null,
    width: 0,
    height: 0,
    mouseX: 0,
    mouseY: 0,
    mouseLeaveDelay: null,
    currentTicket: 0,
    tickets: [
      {
        name: "Girih",
        body:
          "<p>伊斯蘭禁止偶像崇拜，所以在建築裝飾上廣泛使用自然界的花草圖形變化、幾何線條組合以及書法。現存的前現代伊斯蘭建築幾何繪圖可以分三類：網格平面圖；二維的幾何繪圖（用於護牆和立面的裝飾）；三維的幾何繪圖（用於拱頂、圓頂的裝飾）。這些幾何圖案在阿拉伯文裡叫aqd，波斯文叫girih，都是「knot」的意思。</p><p>那個年代裡，科學和工藝技術之間並沒有清晰的界線，因此建築界的工人會遵循數學家提出的一些原理和指引來工作。而因為其精準以及與重要的哲學概念息息相關，數學和幾何學成為很受尊重的科學，因此建築設計因其幾何元素也有了超驗的意義——例如幾何學會比擬為天體運行規律，或者和人體身體動作的關係。</p><p>此圖原型是屬於二維圖案的放射性網格圖則，這個類別的構圖線和最終圖樣被視為兩個不同的層面（layers），是根據重複圖形的放射性移動而形成新的圖案構成。而這些不同的移動軌跡的結合，使得視線經驗有超越單一視角的可能（單眼視覺），而進入一個多重視角/視覺的世界（a realm of polycularity）。其中，不可見的層面（layer）是可見圖像的基礎，而在尺規繪圖的過程裡，即處於不可見的和可見的兩個階段之間，是發人深思的過程——哪些線條被隱去，哪些線條被呈現——這個中間的階段可比擬為一種認識論上的探索（epistemological quest），是存在（being）處於中間的一個狀態，讓人思考存在與本體的意義、看不見的想法（ideas）和可見的物（things）之間的創造性的聯繫。</p><p>（參考Hooman Koliji (2016)的論文“Gazing Geometries: Modes of Design Thinking in Pre-Modern Central Asia and Persian Architecture”）</p>",
      },
      {
        name: "Schematic",
        body:
          "<p>在中世紀伊斯蘭的黃金時代，其哲學、數學、天文、醫學、文學等都有矚目的發展，當中也出現許多精通不同學科的博學者（polymath）。那個時期的伊斯蘭學者將音樂視為一種數學的科學，與代數、幾何、天文並稱為中世紀的「四藝」。</p><p>此圖原型來自Safi al-Din al-Urmawi的《論諧和》，他以圖解分析四聲音階（tetrachord）和五聲音階(pentachord）中音程的不同組合。Safi al-Din al-Urmawi的著作對研究伊斯蘭音樂理論的歷史極為重要。他同時也是一位物理學家、書法家、文學作家、烏德琴演奏家。</p>",
      },
      {
        name: "Notation",
        body:
          "<p>希瓦城（今位於烏茲別克）的音樂家在19世紀下半葉發展出為當地木卡姆而設的彈撥爾（tanbur）指法譜，是中亞音樂首次嘗試建立標準化的記譜系統。</p><p>此圖原型參考自詩人、音樂家、書法家 Muhammad Kamil Devani 在1920年代於希瓦整理的手稿。此譜是從右至左閱讀，橫線代表彈撥爾上對應的音階，原點表示左手按弦的位置，豎線則只為方便閱讀而設。彈撥爾伴奏的詩詞則標記在橫線上。</p>",
      },
    ],
  }),
  mounted() {
    // this.sound.fade(1, 0, 500);
    var vm = this;

    // var sound = new Howl({
    //   src: [require("../../public/music/ticket-g.mp3")],
    // });
    // this.soundOpening.once("load", function() {
    //   vm.sound.play();
    // });
    // this.$store.commit("updateSoundTicket", sound);

    gsap.set(this.$refs.ticket, {
      opacity: 0,
      display: "none",
    });
    gsap.set(this.$refs.content, { opacity: 0, display: "none" });

    const tlShow = new gsap.timeline({
      delay: 0.2,
    });
    tlShow.to(this.$refs.content, {
      opacity: 1,
      duration: 0.5,
      ease: Power2.easeOut,
      display: "flex",
    });
  },
  computed: {
    mousePX() {
      return this.mouseX / this.width;
    },
    mousePY() {
      return this.mouseY / this.height;
    },
    cardStyle() {
      const rX = this.mousePX * 45;
      const rY = this.mousePY * -45;
      return {
        transform: `rotateY(${rX}deg) rotateX(${rY}deg)`,
      };
    },
    sound() {
      return this.$store.state.sound.opening;
    },
  },
  methods: {
    handleMouseMove(e) {
      this.mouseX = e.pageX - this.$refs.card.offsetLeft - this.width / 2;
      this.mouseY = e.pageY - this.$refs.card.offsetTop - this.height / 2;
    },
    handleMouseEnter() {
      clearTimeout(this.mouseLeaveDelay);
    },
    handleMouseLeave() {
      this.mouseLeaveDelay = setTimeout(() => {
        this.mouseX = 0;
        this.mouseY = 0;
      }, 1000);
    },
    selectTicket(i) {
      this.currentTicket = i;

      this.$gtag.event("Select Ticket", {
        event_category: "Ticket",
        event_label: this.tickets[i].name,
      });
      this.svgLoaded();
    },
    svgLoaded() {
      switch (this.currentTicket) {
        case 0:
          this.ticketGirihShow();
          break;
        case 1:
          this.ticketSchShow();
          break;
        case 2:
          this.ticketNotationShow();
      }
    },
    createTicketSound(i) {
      this.$store.commit("updateOpeningLoop", false);
      if (this.mainTrack?.playing()) {
        this.mainTrack.stop();
      }
      if (i == 0) {
        this.mainTrack = new Howl({
          src: ["music/ticket-g.mp3"],
          loop: false,
        });
      } else if (i == 1) {
        this.mainTrack = new Howl({
          src: ["music/ticket-s.mp3"],
          loop: false,
        });
      } else {
        this.mainTrack = new Howl({
          src: ["music/ticket-n.mp3"],
          loop: false,
        });
      }
    },
    contentOver() {
      var tl = gsap.timeline();
      tl.to(this.$refs.content, {
        opacity: 1,
        duration: 0.5,
        ease: Power2.easeOut,
      });
    },
    contentOut() {
      var tl = gsap.timeline();
      tl.to(this.$refs.content, {
        opacity: 0.05,
        duration: 0.5,
        ease: Power2.easeOut,
      });
    },
    ticketGirihShow() {
      var tl = gsap.timeline();
      var gs = document.querySelectorAll("#girih > g");
      tl.set(gs, {
        opacity: 0,
        scale: 0.8,
        rotate: "20deg",
        transformOrigin: "50% 50%",
      });
      tl.to(gs, {
        opacity: 1,
        scale: 1,
        rotate: "0deg",
        ease: Power2.easeOut,
        stagger: {
          each: 0.01,
          grid: "auto",
          from: "left",
          amount: 1,
        },
      });
    },
    ticketSchShow() {
      var tl = gsap.timeline();
      var gs = document.querySelectorAll(
        "#schematic > path, #schematic g, #schematic > line"
      );
      tl.set(gs, {
        opacity: 0,
      });
      tl.to(gs, {
        opacity: 1,
        ease: Power2.easeOut,
        stagger: {
          each: 0.01,
          amount: 1,
        },
      });
    },
    ticketNotationShow() {
      var tl = gsap.timeline();
      var lines = document.querySelectorAll(
        "#notation-child #lines-group > line"
      );
      var dot = document.querySelectorAll("#notation-child #dot-group circle");
      var vertLines = document.querySelectorAll(
        "#notation-child #vert-lines-group line"
      );
      var arrow = document.querySelectorAll(
        "#notation-child #arrow-group polyline"
      );

      tl.set([lines, dot, vertLines, arrow], {
        opacity: 0,
      });
      tl.set(vertLines, {
        height: 0,
      });
      tl.set(arrow, {
        y: -20,
      });
      tl.to(lines, {
        opacity: 1,
        ease: Power2.easeOut,
        stagger: {
          from: "top",
          ease: Power2.easeOut,
          amount: 0.8,
        },
      });
      tl.to(
        dot,
        {
          opacity: 1,
          ease: Power2.easeOut,
          stagger: {
            each: Math.random() * 0.05,
            from: "top",
            ease: Power2.easeOut,
          },
        },
        0.3
      );
      tl.to(
        vertLines,
        {
          height: "auto",
          opacity: 1,
          ease: Power2.easeOut,
          stagger: {
            each: Math.random() * 0.05,
            amount: 4,
          },
        },
        0.3
      );
      tl.to(
        arrow,
        {
          opacity: 1,
          y: 0,
          ease: Power2.easeOut,
          stagger: {
            each: 0.3,
            amount: 2,
          },
        },
        1
      );
    },
    getTicket() {
      this.createTicketSound(this.currentTicket);
      this.mainTrack.once("load", () => {
        this.mainTrack.stop();
        this.mainTrack.play();
        this.getInAnimation();
      });
    },
    getInAnimation() {
      var vm = this;
      var tl = gsap.timeline();
      if (this.currentTicket == 1) {
        tl.to(".ticket-menu-container .menu-item", {
          opacity: 0,
          duration: 2,
          pointerEvents: "none",
          stagger: {
            amount: 2,
          },
          ease: Power2.easeOut,
        });
        tl.to(
          ".ticket-body-container",
          {
            opacity: 0,
            duration: 1.5,
            pointerEvents: "none",
            ease: Power2.easeOut,
          },
          "-=.5"
        );
        tl.to(
          ".btn-ticket",
          {
            opacity: 0,
            duration: 2,
            pointerEvents: "none",
            ease: Power2.easeOut,
          },
          "-=.5"
        );
      }
      tl.to(this.$refs.content, {
        opacity: 0,
        duration: 2,
        display: "none",
        ease: Power2.easeOut,
      });
      tl.fromTo(
        this.$refs.ticket,
        {
          opacity: 0,
          transform: "translateY(100%)",
        },
        {
          opacity: 1,
          transform: "translateY(0%)",
          display: "flex",
          duration: 5,
          ease: Power4.easeInOut,
        }
      );
      tl.set(".img-ticket-container", {
        boxShadow: "0 0 0 calc(100vw + 100vh) rgba(35, 31, 32,0)",
        display: "block",
      });
      tl.to(".img-ticket-container", {
        boxShadow: "0 0 0 calc(100vw + 100vh) rgba(35, 31, 32,1)",
        duration: 2,
        ease: Power2.easeIn,
      });

      tl.to(
        [this.$refs.ticket, ".ticket-pattern-container"],
        {
          transform: "translateY(-100vh)",
          duration: 5,
          ease: Power4.easeIn,
          onComplete: function() {
            vm.gotoNextPage();
          },
        },
        "+=1"
      );
    },
    gotoNextPage() {
      this.$router.push({ name: "Museum" });
    },
  },
};
</script>

<style lang="scss">
::-webkit-scrollbar {
    width: 12px;
}
 
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(255,255,255,0.2); 
    border-radius: 10px;
}
 
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 4px $color--brown; 
}

.ticket-container {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 10;
}
.img-ticket-container {
  display: block;
  width: 100%;
  height: auto;
  max-width: 330px;
  max-height: 620px;
  margin: auto;
  transition: all 1s cubic-bezier(0.215, 0.61, 0.355, 1);
  z-index: 2;
  /* pointer-events: none; */
}
.img-ticket {
  pointer-events: none;
}
.text--brown {
  color: $color--brown;
}
.ticket-info-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 600px;
  // height: 400px;
  padding: 1.5rem 2rem 3rem;
  background: $color--black-lighten-90;
  z-index: 99;
  transition: opacity 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.ticket-menu-container {
  display: flex;
  flex-direction: row;
  padding: 1rem 1.5rem;
  .ticket-menu-item {
    cursor: pointer;
    font-size: 1.3rem;
    margin: 0 1rem;
    color: $color--brown-darken;
    transition: color 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    &:hover {
      color: $color--brown;
    }
  }
  .ticket-menu-item--active {
    color: $color--brown;
  }
}
.ticket-body-container {
  overflow-y: scroll;
  padding: 0 1rem 2rem;
  position: relative;
  width: 100%;
  height: 300px;
}
.ticket-body {
  position: absolute;
  top: 0;
  left: 0;
  padding-right:1.2rem;
}
.btn-ticket {
  color: $color--black-lighten;
  background: $color--brown;
}
.ticket-pattern-container {
  position: absolute;
  width: 100vh;
  height: 100vh;
  // display: flex;
  // align-items: flex-end;
  // justify-content: flex-end;
  // top: 50%;
  right: 0;
  // transform: translate(25%, -50%);
  z-index: 1;
  > svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

#ticket-2 {
  width: 100vw;
  left: 0;
}

@media only screen and (max-width: 960px) {
  #ticket-2 {
    width: 100vh;
    right: 0;
    left: auto;
  }
}
</style>
